import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router,NavigationEnd } from '@angular/router';
import { AppStorage } from './core/appstorage';
import { LocalizationService } from './services/localization.service';
import { WebsiteSettingsDto } from './dto/customersDto';
import { CustomersService } from './services/customers.service';
import { FaviconService } from './favicon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nobleui-angular';
  event$;
  currentUrl;
  websiteSettingsDto = new WebsiteSettingsDto();
  constructor(
    private appStorage: AppStorage,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private faviconService:FaviconService,
    private customerService:CustomersService,
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;

        // if(this.currentUrl != '/auth/login' && this.currentUrl != '/auth/settings'){
        //   if (this.appStorage.getFromSession('user') == null || this.appStorage.getFromSession('user') == undefined) {
        //     this.router.navigate(['/auth/login']);
        //   }
        // }
        
      }
    });
  }

  ngOnInit(): void {
    
    if(this.appStorage.getFromSession('language') == null || this.appStorage.getFromSession('language') == undefined){
      this.appStorage.saveToSession('language','de');
    }
    // if(this.appStorage.getFromLocal('webSettings') == null || this.appStorage.getFromLocal('webSettings') == undefined){
    this.getWebsiteSettings();
    // }
    var lang = (this.appStorage.getFromSession("language"))?this.appStorage.getFromSession("language"):'de';
    this.localizationService.getLocalization(lang).subscribe((response) => {
    this.localizationService.saveToMap('localization',response);
    })

  }

  getWebsiteSettings() {
    // Check cache first
    const cachedSettings = this.appStorage.getFromLocal('webSettings');
    if (cachedSettings?.favicon) {
      this.faviconService.updateFavicon(cachedSettings.favicon);
    }

    const data = { path: 'getWebsiteSettings' };
    this.customerService.getWebSiteSettings(data).subscribe({
      next: (response) => {
        if (response?.statusCode === 200 && response.data) {
          window.localStorage.removeItem("webSettings");
          this.appStorage.saveToLocal('webSettings', response.data);
          this.faviconService.updateFavicon(response.data.favicon);
        }
      },
      error: () => {
        this.faviconService.updateFavicon(this.faviconService.defaultFavicon);
      }
    });
  }

  private updateFavicon(url: string) {
    console.debug('[Favicon] Starting update process for:', url);
  
    // 1. Show existing favicon links
    const existingLinks = document.querySelectorAll("link[rel~='icon']");
    console.log('[Favicon] Existing links:', existingLinks);
  
    // 2. Remove all existing favicon declarations
    existingLinks.forEach(link => {
      console.log('[Favicon] Removing old link:', link);
      link.parentNode?.removeChild(link);
    });
  
    // 3. Create new link element with cache busting
    const newLink = document.createElement('link');
    const cacheBustedUrl = `${url}?v=${new Date().getTime()}`;
    console.log('[Favicon] New URL:', cacheBustedUrl);
  
    newLink.rel = 'icon';
    newLink.href = cacheBustedUrl;
    
    // 4. Add type attribute based on file extension
    const fileType = url.split('.').pop()?.toLowerCase();
    newLink.type = fileType === 'png' ? 'image/png' :
                  fileType === 'svg' ? 'image/svg+xml' :
                  'image/x-icon';
    console.log('[Favicon] Setting type to:', newLink.type);
  
    // 5. Add error handling
    newLink.onerror = (err) => {
      console.error('[Favicon] Failed to load:', err);
      console.warn('[Favicon] Falling back to default');
      this.updateFavicon('/assets/default-favicon.ico');
    };
  
    // 6. Verify DOM insertion
    const head = document.getElementsByTagName('head')[0];
    if (!head) {
      console.error('[Favicon] No head element found!');
      return;
    }
  
    head.appendChild(newLink);
    console.log('[Favicon] New link added to DOM:', newLink);
  
    // 7. Force refresh for stubborn browsers
    console.log('[Favicon] Attempting browser refresh hack');
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = '/favicon.ico';
    document.body.appendChild(iframe);
    
    setTimeout(() => {
      console.log('[Favicon] Cleaning up iframe');
      document.body.removeChild(iframe);
    }, 100);
  
    // 8. Verify network request
    const img = new Image();
    img.src = cacheBustedUrl;
    img.onload = () => console.log('[Favicon] Image loaded successfully');
    img.onerror = () => console.error('[Favicon] Network load failed');
  
    // 9. Final verification
    setTimeout(() => {
      const currentFavicon:any = document.querySelector("link[rel~='icon']");
      console.log('[Favicon] Current favicon after update:', currentFavicon?.href);
    }, 500);
  }

  // private updateFavicon(url: string) {
  //   const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement || document.createElement('link');
  //   link.rel = 'icon';
  //   link.href = url;
  //   document.getElementsByTagName('head')[0].appendChild(link);
  // }

}
