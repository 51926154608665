
export class CustomerByIdDto {
  customers_id: string;
  path: string = 'getCustomerById';
}

export class AppSettingsByCustomerIdDto {
  customers_id: string;
  path: string = 'getAppSettingByCustomerId';
}
export class DeleteCustomersDto{
  customers_id: any[];
  path: string = 'deleteCustomer';
}
export class AllCustomersDto {
    path: string = 'getAllCustomers';
  }

export class packageListDto {
    path: string = 'getPackageList';
}  

export class CustomerDto{ 
    customers_name: string;
    emailAddress: string;
    created_on: string;
    created_by: string;
    active: any;
    notice_date: string;
    max_mediathek_size: string;
    max_playlists: string;
    max_sublocations: string;
    simple_customer: any = 0;
    packages_id: string;
    firstname: string;
    lastname: string;
    email: string;
    status: string;
    users_code: string;
    locations_name: string;
    locations_contact_name: string;
    locations_contact_mail: string;
    locations_contact_comment: string;
    locations_street: string;
    locations_city: string;
    locations_zip: string;
    locations_phone: string;
    locations_is_active: string;
    users_id: string;
    locations_id: string;
    customers_id:string;
    modified_on: string;
    modified_by: string;
    path: string;
}

export class UserPrivilegeForCustomersDto {
  users_id: string;
  path: string = 'getUserPrivilegeForCustomers';
}

export class CustomersFilterDto {
  users_id: string;
  path: string = 'updateUserFiltersForCustomers';
  isCustomerId: boolean;
  isCustomerDesignation: boolean;
  isCustomerPackage: boolean;
  isCustomerActiveEndDevices: boolean;
  isCustomerTerminals: boolean;
  isCustomerPlaylists: boolean;
  isCustomerStorageSize: boolean;
  isCustomerLocation: boolean;
  isCustomerContactPerson: boolean;
  isCustomerContactEmail: boolean;
  isCustomerComment: boolean;
  isCustomerCreatedOn: boolean;
  isCustomerChangedOn: boolean;
  isCustomerActive: boolean;
  isCustomerSimpleUser: boolean;
  isCustomerCreatedBy: boolean;
  isCustomerChangedBy: boolean;
  customerColumnsOrder:any;
}

export class AppSettingsDto {
  base_url: string;
  upload_file_max_size: string;
  thumbnail_max_width: string;
  upload_file_max_width: string;
  upload_file_max_height: string;
  thumbnail_max_height: string;
  emailaddress: string;
  items_per_page: string;
  app_version: string;
  app_file: string;
  status_time: string;
}

export class WebsiteSettingsDto {
  webapp_name1_en: string;
  webapp_name1_de: string;
  webapp_name2_en: string;
  webapp_name2_de: string;
  left_menu_name_en: string;
  left_menu_name_de: string;
  left_menu_logo: string;
  login_logo: string;
  favicon: string;
}