import { HttpHeaders } from '@angular/common/http';

export class AppConstants {

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public localizationPath: string = 'assets/i18n/';

    //  public serverbaseurl: string = 'http://localhost:8000/';
    //  public downloadFile: string = 'http://localhost:8000/core/general_calls/downloadFile.php';
    //  public locationAttachmentUploadPath: string = 'http://localhost:8000/core/general_calls/upload.php';
    //  public emailAttachmentUploadPath: string = 'http://localhost:8000/core/general_calls/uploadEmail.php';
    //  public uploadProfilePath: string = 'http://localhost:8000/core/general_calls/uploadProfile.php';
    //  public serverPath: string = 'http://localhost:8000/webservices.php';
    //  public appEmailUploadPath: string = 'http://localhost:4200/directclipweb/assets/';
    //  public uploadMedia: string = 'http://localhost:8000/uploadMedia.php';
    //  public sendReportEmail: string = 'http://localhost:8000/core/cron_job/email.php';
    //  public uploadedMedia: string = 'media/'; 

  ///////////////// Test dev Version/////////////////////////////
  // public serverbaseurl: string = 'https://dev.simplyonair.de/directclipweb/';
  // public downloadFile: string = 'https://dev.simplyonair.de/directclipweb/core/general_calls/downloadFile.php';
  // public locationAttachmentUploadPath: string = 'https://dev.simplyonair.de/directclipweb/core/general_calls/upload.php';
  // public emailAttachmentUploadPath: string = 'https://dev.simplyonair.de/directclipweb/core/general_calls/uploadEmail.php';
  // public uploadProfilePath: string = 'https://dev.simplyonair.de/directclipweb/core/general_calls/uploadProfile.php';
  // public uploadSettingsPath: string = 'https://dev.simplyonair.de/directclipweb/core/general_calls/uploadSettings.php';
  // public serverPath: string = 'https://dev.simplyonair.de/directclipweb/webservices.php';
  // public appEmailUploadPath: string = '/';
  // public uploadMedia: string = 'https://dev.simplyonair.de/directclipweb/core/general_calls/uploadMedia.php';
  // public sendReportEmail: string = 'https://dev.simplyonair.de/directclipweb/core/cron_job/email.php';
  // public uploadedMedia: string = 'media/'; 


  ///////////////// Live Root Version/////////////////////////////
  public serverbaseurl: string = 'https://www.direct-clip.de/';
  public downloadFile: string = 'https://www.direct-clip.de/core/general_calls/downloadFile.php';
  public locationAttachmentUploadPath: string = 'https://www.direct-clip.de/core/general_calls/upload.php';
  public emailAttachmentUploadPath: string = 'https://www.direct-clip.de/core/general_calls/uploadEmail.php';
  public uploadProfilePath: string = 'https://www.direct-clip.de/core/general_calls/uploadProfile.php';
  public uploadSettingsPath: string = 'https://www.direct-clip.de/core/general_calls/uploadSettings.php';
  public serverPath: string = 'https://www.direct-clip.de/webservices.php';
  public appEmailUploadPath: string = 'https://www.direct-clip.de/';
  public uploadMedia: string = 'https://www.direct-clip.de/core/general_calls/uploadMedia.php';
  public sendReportEmail: string = 'https://www.direct-clip.de/core/cron_job/email.php';
  public uploadedMedia: string = 'media/';

}
