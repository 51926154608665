import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Inject,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import MetisMenu from "metismenujs/dist/metismenujs";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { Router, NavigationEnd } from "@angular/router";
import { LocalizationService } from "src/app/services/localization.service";
import { AppStorage } from "src/app/core/appstorage";
import { WebsiteSettingsDto } from "src/app/dto/customersDto";
import { CustomersService } from "src/app/services/customers.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild("sidebarToggler") sidebarToggler: ElementRef;

  menuItems = [];
  localization: any;
  userPermissions: any;
  userData: any;
  websiteSettings = new WebsiteSettingsDto();
  @ViewChild("sidebarMenu") sidebarMenu: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private localizationService: LocalizationService,
    private appStorage: AppStorage,
    private customerService:CustomersService,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia("(max-width: 991px)").matches) {
          this.document.body.classList.remove("sidebar-open");
        }
      }
    });
  }

  ngOnInit(): void {
    //   this.menuItems = MENU;

    var lang = this.appStorage.getFromSession("language")
      ? this.appStorage.getFromSession("language")
      : "en";
    this.localizationService.getLocalization(lang).subscribe((response) => {
      this.localizationService.saveToMap("localization", response);
    });
    if(this.appStorage.getFromLocal('webSettings') == null || this.appStorage.getFromLocal('webSettings') == undefined){
      this.getWebsiteSettings();
      }
    this.localization =
    this.localizationService.getFromMap("localization").menu;
    this.websiteSettings = this.appStorage.getFromLocal("webSettings")!||{};
    this.userPermissions = this.appStorage.getFromSession("userPermissions");
    this.userData = this.appStorage.getFromSession("user");

    var titleObj = {
      label: "",
      isTitle: true,
    };

    var customersObj = {
      label: this.localization.customers,
      icon: "users",
      link: "/customers",
      subItems: [],
    };

    var emailSettingsObj = {
      label: this.localization.emailSettings,
      icon: "mail",
      link: "/email-settings",
      subItems: [],
    };
    var websiteSettingsObj = {
      label: this.localization.websiteSettings,
      icon: "settings",
      link: "/website-settings",
      subItems: [],
    };

    var dashboardObj = {
      label: this.localization.dashboard,
      icon: "home",
      link: "/dashboard",
    };
    var locationsObj = {
      label: this.localization.location,
      icon: "map-pin",
      // subItems: [],
      link: "/locations/show-locations",
    };
    var deviceObj = {
      label: this.localization.devices,
      icon: "map",
      // subItems: [],
      link: "/devices/show-devices",
    };
    var ticketObj = {
      label: this.localization.tickets,
      icon: "credit-card",
      // subItems: [],
      link: "/devices/show-tickets",
    };

    var ticketSettingsObj = {
      label: this.localization.ticketSettings,
      icon: "settings",
      // subItems: [],
      link: "/devices/ticket-settings",
    };




    var usersObj = {
      label: this.localization.user,
      icon: "user",
      // subItems: [],
      link: "/users/show-users",
    };

    var groupObj = {
      label: this.localization.groups,
      icon: "users",
      // subItems: [],
      link: "/groups/show-groups",
    };

    var playlistsObj = {
      label: this.localization.playlists,
      icon: "play-circle",
      // subItems: [],
      link: "/playlists/show-playlists",
    };

    var mediathekObj = {
      label: this.localization.mediathek,
      icon: "film",
      // subItems: [],
      link: "/mediathek/show-mediathek",
    };

    var templateObj = {
      label: this.localization.templates,
      icon: "layout",
      // subItems: [],
      link: "/templates/show-templates",
    };

    var reportsObj = {
      label: this.localization.reports,
      icon: "columns",
      // subItems: [],
      link: "/reports/show-reports",
    };

    var showCustomerObj = {
      label: this.localization.showCustomers,
      link: "/customers/show-customers",
    };
    var addCustomerObj = {
      label: this.localization.addCustomers,
      link: "/customers/add-customers",
    };

    // var showLocationsObj = {
    //   label: this.localization.showlocation,
    //   link: "/locations/show-locations",
    // };
    // var addLocationsObj = {
    //   label: this.localization.addlocation,
    //   link: "/locations/add-locations",
    // };
    var showUsers = {
      label: this.localization.showUsers,
      link: "/users/show-users",
    };
    var addUsers = {
      label: this.localization.addUsers,
      link: "/users/add-users",
    };
    var addGroups = {
      label: this.localization.addGroups,
      link: "/groups/add-groups",
    };
    var showGroups = {
      label: this.localization.showGroups,
      link: "/groups/show-groups",
    };
    var showDevices = {
      label: this.localization.showDevices,
      link: "/devices/show-devices",
    };
    var addDevices = {
      label: this.localization.addDevices,
      link: "/devices/add-devices",
    };
    // var showTickets = {
    //   label: this.localization.showTickets,
    //   link: "/devices/show-tickets",
    // };
    // var ticketSettings = {
    //   label: this.localization.ticketSettings,
    //   link: "/devices/ticket-settings",
    // };

    var showPlaylists = {
      label: this.localization.showPlaylists,
      link: "/playlists/show-playlists",
    };
    var addPlaylists = {
      label: this.localization.addPlaylists,
      link: "/playlists/add-playlists",
    };

    var showMediathek = {
      label: this.localization.showMediathek,
      link: "/mediathek/show-mediathek",
    };
    var addMediathek = {
      label: this.localization.addMediathek,
      link: "/mediathek/add-mediathek",
    };

    var showTemplate = {
      label: this.localization.showTemplate,
      link: "/templates/show-templates",
    };
    var addTemplate = {
      label: this.localization.addTemplate,
      link: "/templates/add-templates",
    };

    var showReports = {
      label: this.localization.showReports,
      link: "/reports/show-reports",
    };
    var addReports = {
      label: this.localization.addReports,
      link: "/reports/add-reports",
    };

    /* this.menuItems = [
      {
        label: "",
        isTitle: true,
      },
      {
        label: this.localization.dashboard,
        icon: "home",
        link: "/dashboard",
      },

      {
        label: this.localization.location,
        icon: "map-pin",
        subItems: [
          {
            label: this.localization.showlocation,
            link: "/locations/show-locations",
          },
          {
            label: this.localization.addlocation,
            link: "/locations/add-locations",
          },
        ],
      },

      {
        label: "Devices",
        icon: "map",
        subItems: [
          {
            label: "Show Devices",
            link: "/devices/show-devices",
          },
          {
            label: "Add Devices",
            link: "/devices/add-devices",
          },
        ],
      },
      {
        label: this.localization.user,
        icon: "user",
        subItems: [
          {
            label: this.localization.showUsers,
            link: "/users/show-users",
          },
          {
            label: this.localization.addUsers,
            link: "/users/add-users",
          },
        ],
      },
      {
        label: this.localization.groups,
        icon: "users",
        subItems: [
          {
            label: this.localization.showGroups,
            link: "/groups/show-groups",
          },
          {
            label: this.localization.addGroups,
            link: "/groups/add-groups",
          },
        ],
      },
    ]; */

    if (this.userPermissions.Locationsadd) {
      customersObj.subItems.push(addCustomerObj);
    }

    if (this.userPermissions.Locationslist) {
      customersObj.subItems.push(showCustomerObj);
    }

    // if (this.userPermissions.Locationsadd) {
    //   locationsObj.subItems.push(addLocationsObj);
    // }

    // if (this.userPermissions.Locationslist) {
    //   locationsObj.subItems.push(showLocationsObj);
    // }

    // if (this.userPermissions.SubLocationsadd) {
    //   deviceObj.subItems.push(addDevices);
    // }

    // if (this.userPermissions.SubLocationslist) {
    //   deviceObj.subItems.push(showDevices);
    // }

    // if (this.userPermissions.Ticketslist) {
    //   ticketObj.subItems.push(showTickets);
    // }

    // if (this.userPermissions.Ticketsticketsettings) {
    //   ticketObj.subItems.push(ticketSettings);
    // }

    // if (this.userPermissions.Usersadd) {
    //   usersObj.subItems.push(addUsers);
    // }

    // if (this.userPermissions.Userslist) {
    //   usersObj.subItems.push(showUsers);
    // }

    // if (this.userPermissions.Groupslist) {
    //   groupObj.subItems.push(addGroups);
    // }

    // if (this.userPermissions.Groupsadd) {
    //   groupObj.subItems.push(showGroups);
    // }

    // if (this.userPermissions.Playlistsadd) {
    //   playlistsObj.subItems.push(addPlaylists);
    // }

    // if (this.userPermissions.Playlistslist) {
    //   playlistsObj.subItems.push(showPlaylists);
    // }

    // if (this.userPermissions.Mediathekupload) {
    //   mediathekObj.subItems.push(addMediathek);
    // }

    // if (this.userPermissions.Mediatheklist) {
    //   mediathekObj.subItems.push(showMediathek);
    // }

    // if (this.userPermissions.Templatesadd) {
    //   templateObj.subItems.push(addTemplate);
    // }

    // if (this.userPermissions.Templateslist) {
    //   templateObj.subItems.push(showTemplate);
    // }

    // if (this.userPermissions.Reportsadd) {
    //   reportsObj.subItems.push(addReports);
    // }

    // if (this.userPermissions.Reportslist) {
    //   reportsObj.subItems.push(showReports);
    // }

    this.menuItems.push(titleObj);

    if (
      this.userData.customers_id == undefined ||
      this.router.url == "/customers/show-customers" ||
      this.router.url == "/customers/add-customers"
    ) {
      this.menuItems.push(customersObj);
      this.menuItems.push(emailSettingsObj);
      this.menuItems.push(websiteSettingsObj);
    } else {
      if (this.userPermissions.Dashboarddashboard) {
        this.menuItems.push(dashboardObj);
      }

      if (
        this.userPermissions.Locationsadd ||
        this.userPermissions.Locationslist
      ) {
        this.menuItems.push(locationsObj);
      }

      if (
        this.userPermissions.SubLocationsadd ||
        this.userPermissions.SubLocationslist
      ) {
        this.menuItems.push(deviceObj);
      }

      if (this.userPermissions.Mediatheklist || this.userPermissions.Mediathekupload) {
        this.menuItems.push(mediathekObj);
      }

      if (this.userPermissions.Templateslist || this.userPermissions.Templatesadd) {
        this.menuItems.push(templateObj);
      }

      if (
        this.userPermissions.Playlistslist ||
        this.userPermissions.Playlistsadd
      ) {
        this.menuItems.push(playlistsObj);
      }

      if (this.userPermissions.Reportslist || this.userPermissions.Reportsadd) {
        this.menuItems.push(reportsObj);
      }

      if (
        this.userPermissions.SubLocationsviewalltickets ||
        this.userPermissions.Ticketsticketsettings
      ) {
        this.menuItems.push(ticketObj);
      }

      if (
        // this.userPermissions.SubLocationsviewalltickets ||
        this.userPermissions.Ticketsticketsettings
      ) {
        this.menuItems.push(ticketSettingsObj);
      }



      if (this.userPermissions.Usersadd || this.userPermissions.Userslist) {
        this.menuItems.push(usersObj);
      }

      if (this.userPermissions.Groupsadd || this.userPermissions.Groupslist) {
        this.menuItems.push(groupObj);
      }
    }

    // this.menuItems.push(dashboardObj,locationsObj,deviceObj,usersObj);
    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia(
      "(min-width:992px) and (max-width: 1199px)"
    );
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 110);
    this.sidebarToggler.nativeElement.classList.toggle("active");
    this.sidebarToggler.nativeElement.classList.toggle("not-active");
    if (window.matchMedia("(min-width: 992px)").matches) {
      e.preventDefault();
      this.document.body.classList.toggle("sidebar-folded");
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      e.preventDefault();
      this.document.body.classList.toggle("sidebar-open");
    }
  }

  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle("settings-open");
  }

  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains("sidebar-folded")) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }

  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains("sidebar-folded")) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add("sidebar-folded");
    } else {
      this.document.body.classList.remove("sidebar-folded");
    }
  }

  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove("sidebar-light", "sidebar-dark");
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove("settings-open");
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }

  /**
   * Resets the menus
   */
  resetMenuItems() {
    const links = document.getElementsByClassName("nav-link-ref");

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove("mm-active");
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove("mm-active");
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove("mm-show");
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove("mm-active");

          if (parent3El.classList.contains("side-nav-item")) {
            const firstAnchor = parent3El.querySelector(".side-nav-link-a-ref");

            if (firstAnchor) {
              firstAnchor.classList.remove("mm-active");
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove("mm-show");

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove("mm-active");
            }
          }
        }
      }
    }
  }

  /**
   * Toggles the menu items
   */
  activateMenuItems() {
    const links = document.getElementsByClassName("nav-link-ref");

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]["pathname"]) {
        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add("mm-active");
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add("mm-active");

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add("mm-show");
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add("mm-active");

          if (parent3El.classList.contains("side-nav-item")) {
            const firstAnchor = parent3El.querySelector(".side-nav-link-a-ref");

            if (firstAnchor) {
              firstAnchor.classList.add("mm-active");
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add("mm-show");

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add("mm-active");
            }
          }
        }
      }
    }
  }
  getWebsiteSettings() {
    var data:any={};
    data.path='getWebsiteSettings';
    this.customerService
        .getWebSiteSettings(data)
        .subscribe((response) => {
          if (response) {
            if (response.statusCode === 200) {
              if(response.data){
                this.appStorage.saveToLocal('webSettings',response.data);
                this.websiteSettings=response.data;
              }
            } else {
            }
          }
        });
  }
}
