import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guard/auth.guard';
import { BaseComponent } from './views/layout/base/base.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'locations',
        loadChildren: () => import('./views/pages/locations/locations.module').then(m => m.LocationsModule)
      },
      {
        path: 'mediathek',
        loadChildren: () => import('./views/pages/mediathek/mediathek.module').then(m => m.MediathekModule)
      },
      {
        path: 'templates',
        loadChildren: () => import('./views/pages/templates/templates.module').then(m => m.TemplatesModule)
      },
      {
        path: 'devices',
        loadChildren: () => import('./views/pages/devices/devices.module').then(m => m.DevicesModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'playlists',
        loadChildren: () => import('./views/pages/playlists/playlists.module').then(m => m.PlaylistsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/pages/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./views/pages/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'email-settings',
        loadChildren: () => import('./views/pages/email-settings/email-settings.module').then(m => m.EmailSettingsModule)
      },
      {
        path: 'website-settings',
        loadChildren: () => import('./views/pages/website-settings/website-settings.module').then(m => m.WebsiteSettingsModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('./views/pages/groups/groups.module').then(m => m.GroupsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: 'locations', loadChildren: () => import('./views/pages/locations/locations.module').then(m => m.LocationsModule) },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
