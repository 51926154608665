import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  public readonly defaultFavicon = 'favicon.ico';
  private currentFavicon: HTMLLinkElement;

  constructor(private zone: NgZone) {
    this.initializeFavicon();
  }

  private initializeFavicon() {
    // Run outside Angular zone for direct DOM manipulation
    this.zone.runOutsideAngular(() => {
      this.currentFavicon = document.querySelector('#dynamic-favicon') || this.createFaviconElement();
    });
  }

  private createFaviconElement(): HTMLLinkElement {
    const link = document.createElement('link');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    document.head.appendChild(link);
    return link;
  }

  updateFavicon(url: string): void {
    this.zone.runOutsideAngular(() => {
      const cacheBustedUrl = `${url}?v=${Date.now()}`;
      
      // Update existing element properties
      this.currentFavicon.href = cacheBustedUrl;
      this.currentFavicon.type = this.getMimeType(url);

      // Fallback handling
      this.currentFavicon.onerror = () => {
        this.currentFavicon.href = this.defaultFavicon;
      };

      this.forceFaviconRefresh();
    });
  }

  private getMimeType(url: string): string {
    const ext = (url.split('.').pop() || 'ico').toLowerCase();
    return {
      png: 'image/png',
      svg: 'image/svg+xml',
      ico: 'image/x-icon'
    }[ext] || 'image/x-icon';
  }

  private forceFaviconRefresh() {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = 'about:blank';
    document.body.appendChild(iframe);
    
    setTimeout(() => {
      iframe.contentWindow.location.reload();
      setTimeout(() => document.body.removeChild(iframe), 100);
    });
  }
}