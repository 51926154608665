import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { ResponseDto } from '../dto/responseDto';
import { AppConstants } from '../core/app_constants';
import { Router } from '@angular/router';
import { AllCustomersDto, AppSettingsByCustomerIdDto, CustomerByIdDto, CustomerDto, CustomersFilterDto, DeleteCustomersDto, packageListDto, UserPrivilegeForCustomersDto } from '../dto/customersDto';

@Injectable({
  providedIn: 'root'
})

export class CustomersService {
    constructor(private http: HttpClient,private appConstants: AppConstants,private router: Router) {}

    public addCustomer(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public updateCustomer(dto: CustomerDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public allCustomersList(dto: AllCustomersDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public getCustomerById(dto: CustomerByIdDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public getAppSettingByCustomerId(dto: AppSettingsByCustomerIdDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public deleteCustomers(dto: DeleteCustomersDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }

    public userPrivilegeForCustomers(dto: UserPrivilegeForCustomersDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }

    public updateUserFiltersForCustomers(dto: CustomersFilterDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }

    public getPackageList(dto: packageListDto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public getEmailSettings(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public addEmailSettings(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public updateEmailSettings(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public sendTestEmail(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public getWebSiteSettings(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
    public updateWebSiteSettings(dto): Observable<ResponseDto> 
    {
       return this.http.post<ResponseDto>(this.appConstants.serverPath, dto, this.appConstants.httpOptions);
    }
}