import { Injectable } from '@angular/core';

@Injectable()
export class AppStorage {

  constructor() { }


  signOut() {
    //window.localStorage.clear();
    window.localStorage.removeItem("isLoggedin");
    window.sessionStorage.clear();
  }

  public saveToSession(key: string, data: any) {
    window.sessionStorage.setItem(key,  JSON.stringify(data));
  }
  public getFromSession(key: string): any {
    return JSON.parse(window.sessionStorage.getItem(key));
  }
  public saveToLocal(key: string, data: any) {
    window.localStorage.setItem(key,  JSON.stringify(data));
  }
  public getFromLocal(key: string): any {
    return JSON.parse(window.localStorage.getItem(key));
  }

}
